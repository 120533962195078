<template>
    <client-page>

        <template slot="subHead">
            <sub-visual sh="Partners" tabActive="대리점 개설 문의" bg="/images/sub/visual/sv-partners.jpg" />
        </template>

        <page-section class="page-section--first">

            <tit-wrap-secondary class="pb-0">
                VIITZ와 성공을 함께 할 파트너를 모집합니다.
                <template #TxtAdd>
                    대리점 문의 및 개설안내에 대한 상담은 전화 및 이메일 모두 가능합니다.
                </template>
            </tit-wrap-secondary>

        </page-section>       
            
        <page-section :style="$vuetify.breakpoint.mdAndUp ? 'background-image: url(/images/sub/partners/inquiry/inquiry-bg.jpg)' : 'background-image: url(/images/sub/partners/inquiry/inquiry-bg-mo.jpg)'">
            <tit-wrap class="tit-wrap--sm text-center text-md-left">
                <tit data-aos="fade-up" class="tit--sm white--text">대리점 개설 프로세스</tit>
            </tit-wrap>
            <v-img data-aos="fade-up" data-aos-delay="100" src="/images/sub/partners/inquiry/inquiry-img.svg" max-width="1200" :aspect-ratio="1200 / 250" contain class="w-100 d-none d-md-block" />
            <v-img data-aos="fade-up" data-aos-delay="100" src="/images/sub/partners/inquiry/inquiry-img-mo.svg" max-width="250" :aspect-ratio="250 / 1186" contain class="w-100 mx-auto d-md-none" />
        </page-section>             
            
        <page-section class="pb-0">
            <tit-wrap class="tit-wrap--sm">
                <tit data-aos="fade-up" class="tit--sm">담당 사업부 및 담당자</tit>
            </tit-wrap>
            <v-card tile flat color="grey lighten-5">
                <div class="pa-20px pa-md-30px px-lg-60px py-lg-40px">
                    <v-row class="mx-lg-n20px mx-xl-n50px">
                        <v-col cols="12" md="auto" class="px-lg-20px px-xl-50px">
                            <txt data-aos="fade-up" data-aos-delay="100" class="txt--xs line-height-1 mb-4px mb-md-8px">담당 사업부</txt>
                            <txt data-aos="fade-up" data-aos-delay="150" class="txt--dark font-weight-medium">커머셜부 / 오피스팀</txt>
                        </v-col>
                        <v-col cols="12" md="" class="px-lg-20px px-xl-50px">
                            <txt data-aos="fade-up" data-aos-delay="100" class="txt--xs line-height-1 mb-4px mb-md-8px">담당자</txt>
                            <txt data-aos="fade-up" data-aos-delay="150" class="txt--dark font-weight-medium font-secondary">
                                지형준 팀장 <br class="d-md-none"><span class="d-none d-md-inline"> ( </span>
                                HP : <a href="tel:01050056035" target="_blank">010-5005-6035</a><span class="d-none d-md-inline"> / </span> <br class="d-md-none">
                                E-mail : <a href="mailto:wlgudwns5801@naver.com" target="_blank">wlgudwns5801@naver.com</a><span class="d-none d-md-inline"> / </span> <br class="d-md-none">
                                <a href="mailto:kkss801@effex.co.kr" target="_blank" class="d-inline-block pl-56px pl-md-0">kkss801@effex.co.kr</a>
                                <span class="d-none d-md-inline"> ) </span>
                            </txt>
                        </v-col>
                    </v-row>
                </div>
            </v-card>
        </page-section>    
            
        <page-section class="page-section--last">
            <tit-wrap class="tit-wrap--sm">
                <tit data-aos="fade-up" data-aos-delay="100" class="tit--sm">대리점 개설 문의</tit>
            </tit-wrap>

            <form-input skin="agency" code="agency"/>
        </page-section>

    </client-page>
</template>

<script>
import ClientPage from "@/sets/styles/apps/client-page.vue";
import SubVisual from "@/components/client/sub/sub-visual.vue";
import PageSection from "@/sets/styles/pages/page-section.vue";

import FormInput from "@/components/client/form/form-input.vue";

import TitWrapSecondary from "@/components/publish/parents/typography/tit-wrap-secondary.vue";
import TitWrap from "@/components/publish/styles/typography/tit-wrap.vue";
import Tit from "@/components/publish/styles/typography/tit.vue";
import Txt from "@/components/publish/styles/typography/txt.vue";

export default {
    components: {
        ClientPage,
        SubVisual,
        PageSection,

        FormInput,

        TitWrapSecondary,
        TitWrap,
        Tit,
        Txt,
    },
}
</script>

<style>

</style>