var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "board-container"
  }, [_c('v-row', {
    attrs: {
      "no-gutters": ""
    }
  }, [_c('v-spacer'), _c('v-col', {
    attrs: {
      "cols": "12",
      "md": "auto"
    }
  }, [_c('v-select', {
    staticClass: "v-input--density-compact w-100 mw-md-160px",
    attrs: {
      "items": _vm.regionItems,
      "hide-details": "",
      "outlined": "",
      "dense": ""
    },
    on: {
      "input": function ($event) {
        ((_vm.page = 1) || true) && _vm.init();
      }
    },
    model: {
      value: _vm.filter.region,
      callback: function ($$v) {
        _vm.$set(_vm.filter, "region", $$v);
      },
      expression: "filter.region"
    }
  })], 1)], 1), _c('table', {
    staticClass: "board-list font-secondary mt-12px mt-md-24px"
  }, [_vm._m(0), _c('tbody', _vm._l(_vm.boards, function (board) {
    return _c('tr', {
      key: board === null || board === void 0 ? void 0 : board._id
    }, [_c('td', {
      staticClass: "board-list__txt board-list__txt--first",
      attrs: {
        "align": "center"
      }
    }, [_vm._v(" " + _vm._s(_vm.setRegion(board === null || board === void 0 ? void 0 : board.region)) + " ")]), _c('td', {
      staticClass: "board-list__txt",
      attrs: {
        "align": "center"
      }
    }, [_vm._v(" " + _vm._s(board === null || board === void 0 ? void 0 : board.type) + " ")]), _c('td', {
      staticClass: "board-list__tit",
      attrs: {
        "align": "center"
      }
    }, [_vm._v(" " + _vm._s(board === null || board === void 0 ? void 0 : board.subject) + " ")]), _c('td', {
      staticClass: "board-list__txt",
      attrs: {
        "align": "center"
      }
    }, [_vm._v(" " + _vm._s(board === null || board === void 0 ? void 0 : board.address) + " ")]), _c('td', {
      staticClass: "board-list__txt",
      attrs: {
        "align": "center"
      }
    }, [_vm._v(" " + _vm._s(board === null || board === void 0 ? void 0 : board.contact) + " ")]), _c('td', {
      staticClass: "board-list__txt board-list__txt--absolute",
      attrs: {
        "align": "center"
      }
    }, [_c('v-btn', {
      staticClass: "v-btn--has-bg",
      attrs: {
        "href": `${board === null || board === void 0 ? void 0 : board.url}`,
        "target": "_blank",
        "icon": "",
        "color": "primary"
      }
    }, [_c('icon-point')], 1)], 1)]);
  }), 0)]), _c('div', {
    staticClass: "pagination-wrap"
  }, [_c('v-pagination', {
    attrs: {
      "length": _vm.pageCount,
      "total-visible": 11
    },
    on: {
      "input": _vm.init
    },
    model: {
      value: _vm.page,
      callback: function ($$v) {
        _vm.page = $$v;
      },
      expression: "page"
    }
  })], 1)], 1);

}
var staticRenderFns = [function (){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('thead', {
    staticClass: "d-none d-lg-table-header"
  }, [_c('tr', [_c('th', {
    staticStyle: {
      "width": "10%"
    }
  }, [_vm._v("지역")]), _c('th', {
    staticStyle: {
      "width": "10%"
    }
  }, [_vm._v("유형")]), _c('th', {
    staticStyle: {
      "width": "24%"
    }
  }, [_vm._v("쇼룸/대리점명")]), _c('th', {
    staticStyle: {
      "width": "30%"
    }
  }, [_vm._v("주소")]), _c('th', {
    staticStyle: {
      "width": "17%"
    }
  }, [_vm._v("연락처")]), _c('th', {
    staticStyle: {
      "width": "9%"
    }
  }, [_vm._v("상세보기")])])]);

}]

export { render, staticRenderFns }