<template>
    <div class="board-container">
        <v-row no-gutters>
            <v-spacer />
            <v-col cols="12" md="auto">
                <v-select v-model="filter.region" :items="regionItems" hide-details outlined dense class="v-input--density-compact w-100 mw-md-160px" @input="((page = 1) || true) && init()" />
            </v-col>
        </v-row>
        <table class="board-list font-secondary mt-12px mt-md-24px">
    		<thead class="d-none d-lg-table-header">
                <tr>
                    <th style="width:10%;">지역</th>
                    <th style="width:10%;">유형</th>
                    <th style="width:24%;">쇼룸/대리점명</th>
                    <th style="width:30%;">주소</th>
                    <th style="width:17%;">연락처</th>
                    <th style="width:9%;">상세보기</th>
                </tr>
            </thead>
            <tbody>
                <!-- S: partner -->
                <tr v-for="board in boards" :key="board?._id">
                    <td align="center" class="board-list__txt board-list__txt--first">
                        <!-- 서울•수도권 -->
                        {{ setRegion(board?.region)  }}
                    </td>
                    <td align="center" class="board-list__txt">
                        <!-- 쇼룸/대리점 -->
                        {{ board?.type }}
                    </td>
                    <td align="center" class="board-list__tit">
                        <!-- 도시공간 -->
                        {{ board?.subject }}
                    </td>
                    <td align="center" class="board-list__txt">
                        <!-- 서울 서초구 반포대로14길 36 -->
                        {{ board?.address }}
                    </td>
                    <td align="center" class="board-list__txt">
                        <!-- 02-544-1999 -->
                        {{ board?.contact }}
                    </td>
                    <td align="center" class="board-list__txt board-list__txt--absolute">
                        <v-btn :href="`${board?.url}`" target="_blank" icon color="primary" class="v-btn--has-bg"><icon-point /></v-btn>
                    </td>
                </tr>
                <!-- E: partner -->
    		</tbody>
    	</table>
        <div class="pagination-wrap">
            <v-pagination v-model="page" :length="pageCount" :total-visible="11" @input="init" />
        </div>
    </div>
</template>

<script>
import api from "@/api"
import IconPoint from "@/components/publish/styles/icons/icon-point.vue";

export default {
    components: {
        IconPoint,
    },
    data() {
        return {
            filter: {
                code: "partners",
                region: null
            },
            regionItems: [
                { text: ":: 전체 ::", value: null },
                { text: "서울•수도권", value: "Metropolitan" },
                { text: "수도권 외", value: "UnMetropolitan" },
            ],
            pageCount: 0,
            limit: 13,
            page: 1,

            boards: []
        }
    },
    async mounted() {
        await this.init();
    },
    methods: {
        async init() {
            let { summary, boards } = await api.v1.boards.gets({
                    headers: {
                        skip: (this.page - 1) * this.limit,
                        limit: this.limit,
                    },
                    params: this.filter,
            })
            this.boards = boards;
            this.pageCount = Math.ceil(summary.totalCount / this.limit);
        },
        setRegion(region) {
            let regionMap = {
                "Metropolitan": "서울•수도권",
                "UnMetropolitan": "수도권 외"
            }
            return regionMap[region];
        }
    }
}
</script>

<style lang="scss" scoped>
.board-list tbody tr{
    cursor:default;
}
.board-list tr td.board-list__tit{
    padding: 4px 50px 14px 10px;
}
.board-list__txt--absolute{
    position: absolute;
    top: 2px;
    right: 0;
    &::before{
        display: none;
    }
    .v-btn.v-size--default.v-btn--icon {
        width: 28px !important;
        height: 28px !important;
        .icon--size-default{
            height: 18px;
        }
    }
}
@media (min-width:576px){
}
@media (min-width:768px){
}
@media (min-width:1024px){
    .board-list tr td.board-list__tit{
        text-align: center;
        padding: 16px 20px;
    }
    .board-list__txt--absolute{
        position: relative;
        top: 0;
        right: unset;
        .v-btn.v-size--default.v-btn--icon {
            width: var(--btn-height) !important;
            height: var(--btn-height) !important;
            .icon--size-default{
                height: 20px;
            }
        }
    }
}
@media (min-width:1200px){
}

</style>